import { ModalProgrammatic as Modal } from 'buefy'

const create = (parent, page, component, name, type = null) => {
	history.pushState({}, '', `/${page}`)
	history.pushState({}, '', `${page}/create`)

	var modal = Modal.open({
		parent: parent,
		component: component,
		scroll: 'keep',
		customClass: 'is-' + page,
		trapFocus: true,
		onCancel: () => {
			history.pushState({}, '', `/${page}`)
		},
		props: {
			name: name,
			type: type
		}
	})

	modal.$on('close', () => history.pushState({}, '', `/${page}`))
}

const update = (parent, page, id, component, name, root = null, type = null) => {
	history.pushState({}, '', `/${page}`)
	history.pushState({}, '', `${page}/edit/${id}`)

	var modal

	if (root === 'root') {
		modal = Modal.open({
			parent: parent,
			component: component,
			scroll: 'clip',
			customClass: 'is-' + page,
			trapFocus: true,
			onCancel: () => {
				history.pushState({}, '', `/${page}`)
			},
			props: {
				id: id,
				name: name,
				root: root,
				type: type
			}
		})
	} else {
		modal = Modal.open({
			parent: parent,
			component: component,
			scroll: 'clip',
			customClass: 'is-' + page,
			trapFocus: true,
			onCancel: () => {
				history.pushState({}, '', `/${page}`)
			},
			props: {
				id: id,
				name: name,
				type: type
			}
		})
	}

	modal.$on('close', () => history.pushState({}, '', `/${page}`))
}


const detail = (parent, page, id, component, name, type = null) => {
	history.pushState({}, '', `/${page}`)
	history.pushState({}, '', `${page}/detail/${id}`)

	var modal = Modal.open({
		parent: parent,
		component: component,
		scroll: 'clip',
		customClass: 'is-' + page,
		trapFocus: true,
		onCancel: () => {
			history.pushState({}, '', `/${page}`)
		},
		props: {
			id: id,
			name: name,
			type: type
		}
	})

	modal.$on('close', () => history.pushState({}, '', `/${page}`))
}

export { create, update, detail }
