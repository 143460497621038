<template>
	<Layout>
		<Birthday />
		<Header :bordered="false" />
		<div class="dashboard">
			<b-loading :is-full-page="false" v-model="loading"></b-loading>
			<b-carousel-list v-if="overview.length > 0" animated="slide" :data="overview" :has-drag="true" :items-to-show="isDesktop ? 5 : isTablet ? 4 : 2">
				<template #item="overview">
					<div class="dashboard__box px-2 pt-3 pb-5">
						<h4 class="dashboard__box__value">
							{{ formatCompactNumber(overview.value) }}
						</h4>
						<span v-if="overview.type.charAt(0) == '$'" class="dashboard__box__type">{{ $tc('menu.roles', [overview.type.length]) }}</span>
						<span v-else class="dashboard__box__type">{{ overview.type }}</span>
					</div>
				</template>
			</b-carousel-list>
			<span v-else class="dashboard__dashboard">
				<b-skeleton v-for="i in 5" :key="i" height="120" class="dashboard__box mr"></b-skeleton>
			</span>
			<section v-if="user" class="columns is-multiline is-mobile mt-3">
				<div v-for="t in transactions" :key="`transac-${t.id}`" class="column is-12-mobile is-6-tablet is-4-desktop">
					<Transaction :transaction="t" :userId="user.id" :userMaster="user.permission_id == 1" />
				</div>
			</section>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Transaction from '@/components/Transaction'
import Api from '@/services/api'
import Birthday from '@/components/Birthday'
import { responsive } from '@/mixins/responsive'
import { mapState } from 'vuex'
import '@/mixins/generic'

export default {
	name: 'Dashboard',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Transaction,
		Birthday
	},
	data() {
		return {
			loading: false,
			bordered: true,
			dataCollection: null,
			overview: [],
			transactions: []
		}
	},
	mounted() {
		this.getOverview()
		this.getTransactions()
	},
	methods: {
		fillChartData() {
			let labelsChart = [], dataChart = []

			this.overview.forEach((o, i) => {
				if (i > 0) {
					labelsChart.push(`${o.type} (${o.value})`)
					dataChart.push(o.value)
				}
			})

			this.dataCollection ={
				labels: labelsChart,
				datasets: [
					{
						data: dataChart,
						borderColor: '#ffffff',
						backgroundColor: ['#33da6d', '#3300ff', '#ed8e53']
					}
				]
			}
		},
		getOverview() {
			Api.get('dashboard/overview')
				.then(({ data }) => {
					this.overview = data
					this.fillChartData()
				})
				.catch((err) => {
					console.log(err)
				})
		},
		getTransactions() {
			Api.get('transactions/findAll?limit=6')
				.then(({ data }) => {
					this.transactions = data
				})
		}
	},
	computed: {
		...mapState('user', [
			'user'
		])
	}
}
</script>
