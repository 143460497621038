<template>
	<article class="block transaction" :class="{ to: !userMaster && userId == transaction.receiver_id, from: !userMaster && userId == transaction.sender_id }" @click="detail(transaction)">
		<div class="block__content">
			<span class="identification">
				<h3 class="hash">{{ transaction.hash }}</h3>
				<p class="token">{{ transaction.total }} tokens</p>
				<!-- <p class="token"><input type="text" :value="transaction.total" v-money="money"> tokens</p> -->
			</span>
			<span class="users">
				<p class="sender"><strong>Sender:</strong> {{ transaction.sender.hash }}</p>
				<p class="receiver"><strong>Receiver:</strong> {{ transaction.receiver.hash }}</p>
			</span>
		</div>
	</article>
</template>

<script>
import Modal from '@/components/modals/Transaction'
import { update } from '@/mixins/modal'
import { VMoney } from 'v-money'


export default {
	name: 'Transaction',
	directives: {
		money: VMoney
	},
	props: {
		transaction: {
			type: Object,
			required: true
		},
		userId: {
			type: Number,
			required: true
		},
		userMaster: {
			type: Boolean,
			required: true
		},
		type: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			money: {
				decimal: '',
				thousands: '.',
				prefix: '',
				precision: 0
			}
		}
	},
	methods: {
		detail(transaction) {
			update(this, 'transactions', transaction.id, Modal, transaction)
		}
	}
}
</script>
