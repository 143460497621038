<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>Transaction <strong>Details</strong></span>
				<span>{{ name.id }}</span>
			</h4>
		</header>
		<div class="modal-card-body">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<div class="modal-card">
				<p><strong>Hash:</strong> <span>{{ name.hash }}</span></p>
				<p><strong>Token:</strong> <span>{{ name.total }}</span></p>
				<hr>
				<p><strong>Sender:</strong> <span>{{ name.sender.hash }}</span></p>
				<p><strong>Receiver:</strong> <span>{{ name.receiver.hash }}</span></p>
				<hr>
				<p><strong>Date:</strong> <span>{{ format(name.created_at) }}</span></p>
				<p><strong>Time:</strong> <span>{{ hour(name.created_at) }}</span></p>
			</div>
		</div>
	</div>
</template>

<script>
import '@/mixins/generic'

export default {
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		name: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false
		}
	}
}
</script>
